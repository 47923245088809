import React from "react"

import PhoneIcon from "../images/phone.svg"
import EmailIcon from "../images/email.svg"
import StoreLocatorIcon from "../images/icon-store-locator.svg"
import Facebook from "../images/facebook.png"
import Youtube from "../images/youtube.png"
import Instagram from "../images/instagram.png"
import Linkedin from "../images/linkedin.png"
import IconRight from "../images/icon_arrow_right.svg"
import IconDown from "../images/icon_arrow_down.svg"

const Footer = ({ siteTitle }) => (
  <div className="container-full-width non-printable">
    <div className="pt-5 footer text-center d-none d-xl-block">
      <div className="container">
        <footer>
          <div className="row pb-5">
            <div className="col-xl-3">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">Despre</p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium">
                Companie
              </h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/AboutUs"
                  >
                    Despre Noi
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/principiile-penny"
                  >
                    Principiile Companiei
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/expansiune"
                  >
                    Dezvoltare și Expansiune
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="https://www.penny.ro/presa">
                    Comunicate de presă și Publicații
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-xl-3">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">
                Utile și
              </p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium">
                Informații de contact
              </h4>
              <ul className="list-unstyled">
                <li>
                  <div className="d-flex">
                    <div className="mr-1">
                      <img
                        className="mt-2 mr-3 mt-1 ml-3 pl-1"
                        src={PhoneIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-left">
                      <span className="header-icons font-weight-bold well-dark-grey-color">
                        0800 110 111
                      </span>
                      <br />
                      <span className="header-icons well-dark-grey-color">
                        Luni - Sâmbătă 08:00 - 21:00
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <img className="mr-3" src={EmailIcon} alt="" />
                    <a
                      href="https://www.penny.ro/contact"
                      className="footer-link"
                    >
                      Contact
                    </a>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-3">
                    <img
                      className="ml-3 mt-n1"
                      src={StoreLocatorIcon}
                      alt="faq_icon"
                      width="35px"
                      height="35px"
                    />

                    <a
                      href="https://www.penny.ro/magazinul-meu"
                      className="footer-link ml-3 text-nowrap"
                    >
                      Magazinul meu
                    </a>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-xl-3">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">
                Sustenabilitate și
              </p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium">
                Responsabilitate socială
              </h4>
              <ul className="list-unstyled">
                <li>
                  <a href="/sortiment-sustenabil" className="footer-link">
                    Sortiment Sustenabil
                  </a>
                </li>
                <li>
                  <a href="/angajati-societate" className="footer-link">
                    Angajați și societate
                  </a>
                </li>
                <li>
                  <a href="/energie-clima-resurse" className="footer-link">
                    Energie, climă și resurse
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-xl-3">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">
                Urmărește-ne și pe
              </p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium pb-3">
                SOCIAL MEDIA &amp; APP
              </h4>
              <ul className="list-inline list-unstyled">
                <li className="list-inline-item">
                  <a href="https://www.facebook.com/penny.ro">
                    <img className="facebook" src={Facebook} alt="facebook" />
                  </a>
                </li>

                <li className="list-inline-item">
                  <a href="https://www.youtube.com/pennymarketromania">
                    <img className="youtube" src={Youtube} alt="youtube" />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.instagram.com/pennymarket.ro/">
                    <img
                      className="instagram"
                      src={Instagram}
                      alt="instagram"
                    />
                  </a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.linkedin.com/company/129255/">
                    <img className="linkedin" src={Linkedin} alt="linkedin" />
                  </a>
                </li>
                <li className="list-inline-item my-2">
                  <a href="https://play.google.com/store/apps/details?id=int.penny.pennymarket">
                    <img
                      width="100px"
                      alt="alternate text"
                      src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/a482523f-c1d7-4be7-a27e-7b03be1b6d65/Google-Play.png?w=119&fm=webp&lossless=0&q=90"
                      className="google-play"
                    />
                  </a>
                  <a href="https://itunes.apple.com/ro/app/penny-market/id1091585551?mt=8">
                    <img
                      width="100px"
                      alt="alternate text"
                      src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9b3c081c-4d8e-4971-bf73-5b18a475e78f/app-store-badge.svg"
                      className="app-store"
                    />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <div className="footer d-none d-lg-block d-xl-none well">
      <div className="container">
        <footer>
          <div className="row pb-5 text-center">
            <div className="col-md-4">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">Despre</p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium">
                Companie
              </h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/AboutUs"
                  >
                    Despre Noi
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/principiile-penny"
                  >
                    Principiile Companiei
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/expansiune"
                  >
                    Dezvoltare și Expansiune
                  </a>
                </li>
                <li>
                  <a className="footer-link" href="https://www.penny.ro/presa">
                    Comunicate de presă și Publicații
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">
                Utile și
              </p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium">
                Informații de contact
              </h4>
              <ul className="list-unstyled ml-4">
                <li>
                  <div className="d-flex">
                    <div className="mr-1">
                      <img
                        className="mt-2 mr-3 mt-1 ml-3 pl-1"
                        src={PhoneIcon}
                        alt=""
                      />
                    </div>
                    <p className="text-left">
                      <span className="header-icons font-weight-bold well-dark-grey-color">
                        0800 110 111
                      </span>
                      <br />
                      <span className="header-icons well-dark-grey-color">
                        Luni - Sâmbătă 08:00 - 21:00
                      </span>
                    </p>
                  </div>
                </li>
                <li>
                  <div className="d-flex">
                    <img className="mr-3" src={EmailIcon} alt="" />
                    <a
                      href="https://www.penny.ro/contact"
                      className="footer-link"
                    >
                      Contact
                    </a>
                  </div>
                </li>
                <li>
                  <div className="d-flex mt-3">
                    <img
                      className="ml-3 mt-n1"
                      src="/img/nav/icon-store-locator.svg"
                      alt="faq_icon"
                      width="35px"
                      height="35px"
                    />

                    <a
                      href="https://www.penny.ro/magazinul-meu"
                      className="footer-link ml-3 text-nowrap"
                    >
                      Magazinul meu
                    </a>
                  </div>
                </li>
              </ul>
            </div>

            <div className="col-md-4">
              <p className="amatic-bold pt-3 mb-0 footer-text-title">
                Sustenabilitate și
              </p>
              <h4 className="footer-text accentual-heading-h3-font akko-medium">
                Responsabilitate socială
              </h4>
              <ul className="list-unstyled">
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/page/sustenabilitate/implicare-sociala"
                  >
                    Implicare socială
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/page/sustenabilitate/angajati"
                  >
                    Angajați
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/page/sustenabilitate/energie-clima-mediu"
                  >
                    Energie, climă și mediu
                  </a>
                </li>
                <li>
                  <a
                    className="footer-link"
                    href="https://www.penny.ro/page/sustenabilitate/produse-verzi"
                  >
                    Produse verzi
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-md-12">
              <div className="col-xl-3 border-top-white">
                <ul className="list-inline mb-4 pt-2">
                  <li className="list-inline-item pr-4">
                    <p className="amatic-bold mb-0">Urmărește-ne și pe</p>
                  </li>
                  <li className="list-inline-item pr-4">
                    <h4 className="footer-text accentual-heading-h3-font akko-medium">
                      SOCIAL MEDIA &amp; APP
                    </h4>
                  </li>
                  <li className="list-inline-item align-middle">
                    <a href="https://www.facebook.com/penny.ro">
                      <img className="facebook" src={Facebook} alt="facebook" />
                    </a>
                  </li>
                  <li className="list-inline-item align-middle" />
                  <li className="list-inline-item align-middle">
                    <a href="https://www.youtube.com/pennymarketromania">
                      <img className="youtube" src={Youtube} alt="youtube" />
                    </a>
                  </li>
                  <li className="list-inline-item align-middle">
                    <a href="https://www.instagram.com/pennymarket.ro/">
                      <img
                        className="instagram"
                        src={Instagram}
                        alt="instagram"
                      />
                    </a>
                  </li>
                  <li className="list-inline-item align-middle">
                    <a href="https://www.linkedin.com/company/129255/">
                      <img className="linkedin" src={Linkedin} alt="linkedin" />
                    </a>
                  </li>
                  <li className="list-inline-item align-middle">
                    <a href="https://play.google.com/store/apps/details?id=int.penny.pennymarket">
                      <img
                        width="100px"
                        height="29.63px"
                        alt=""
                        src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/a482523f-c1d7-4be7-a27e-7b03be1b6d65/Google-Play.png?w=119&fm=webp&lossless=0&q=90"
                        className="google-play"
                      />
                    </a>
                    <a href="https://itunes.apple.com/ro/app/penny-market/id1091585551?mt=8">
                      <img
                        width="100px"
                        alt=""
                        src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9b3c081c-4d8e-4971-bf73-5b18a475e78f/app-store-badge.svg"
                        className="app-store"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>

    <div className="d-lg-none footer">
      <footer>
        <div className="row pb-1">
          <div
            className="col-sm-12"
            data-toggle="collapse"
            data-target="#about-penny-collapsible"
          >
            <p className="amatic-bold pl-4 mb-1 pt-3 footer-text-title">
              Despre
            </p>
            <h5
              className="ml-0 pl-4 footer-text accentual-heading-h3-font akko-medium"
              id="about-penny-title"
            >
              Companie
            </h5>
            <span
              id="about-penny-regress-button"
              className="regress-button"
              style={{ display: "none" }}
            >
              <img
                src={IconDown}
                width="25px"
                height="25px"
                alt=""
                className="pull-right footer-mobile-arrow-regress"
              />
            </span>
            <span id="about-penny-collapse-button" className="collapse-button">
              <img
                src={IconRight}
                width="25px"
                height="25px"
                alt=""
                className="pull-right footer-mobile-arrow"
              />
            </span>
            <ul
              className="footer-collapsible-list px-0 my-0 collapse footer-collapse"
              id="about-penny-collapsible"
            >
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a className="footer-link" href="https://www.penny.ro/AboutUs">
                  Despre Noi
                </a>
              </li>
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a
                  className="footer-link"
                  href="https://www.penny.ro/principiile-penny"
                >
                  Principiile Companiei
                </a>
              </li>
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a
                  className="footer-link"
                  href="https://www.penny.ro/expansiune"
                >
                  Dezvoltare și Expansiune
                </a>
              </li>
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a className="footer-link" href="https://www.penny.ro/presa">
                  Comunicate de presă și Publicații
                </a>
              </li>
            </ul>
          </div>
          <div
            className="col-sm-12 border-top-white"
            data-toggle="collapse"
            data-target="#contact-penny-collapsible"
          >
            <p className="amatic-bold pl-4 mb-1 pt-3 footer-text-title">
              Utile și
            </p>
            <h5
              className="ml-0 pl-4 footer-text accentual-heading-h3-font akko-medium"
              id="contact-penny-title"
            >
              Informații de contact
            </h5>
            <span
              id="contact-penny-regress-button"
              className="regress-button"
              style={{ display: "none" }}
            >
              <img
                src={IconDown}
                width="25px"
                height="25px"
                alt=""
                className="pull-right footer-mobile-arrow-regress"
              />
            </span>
            <span
              id="contact-penny-collapse-button"
              className="collapse-button"
            >
              <img
                src={IconRight}
                width="25px"
                height="25px"
                alt=""
                className="pull-right footer-mobile-arrow-regress"
              />
            </span>
            <ul
              className="list-unstyled collapse footer-collapse"
              id="contact-penny-collapsible"
            >
              <li>
                <div className="d-flex">
                  <div className="ml-1">
                    <img
                      className="mt-2 pl-2 pr-1 ml-4 mr-3 mr-3 mt-1"
                      src={PhoneIcon}
                      alt=""
                    />
                  </div>
                  <p>
                    <span className="header-icons font-weight-bold well-dark-grey-color mr-3">
                      0800 110 111
                    </span>
                    <br />
                    <span className="header-icons well-dark-grey-color">
                      Luni - Sâmbătă 08:00 - 21:00
                    </span>
                  </p>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <img className="ml-3 mr-3" src={EmailIcon} alt="" />
                  <a
                    href="https://www.penny.ro/contact"
                    className="footer-link"
                  >
                    Contact
                  </a>
                </div>
              </li>
              <li>
                <div className="d-flex mt-3">
                  <img
                    className="mt-n1 mr-1"
                    style={{ marginLeft: "2rem" }}
                    src={StoreLocatorIcon}
                    alt="faq_icon"
                    width="35px"
                    height="30px"
                  />

                  <a
                    href="https://www.penny.ro/magazinul-meu"
                    className="footer-link text-nowrap ml-2"
                  >
                    Magazinul meu
                  </a>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="col-sm-12 border-top-white"
            data-toggle="collapse"
            data-target="#quality-penny-collapsible"
          >
            <p className="amatic-bold pl-4 mb-1 pt-3 footer-text-title">
              Sustenabilitate și
            </p>
            <h5
              className="ml-0 pl-4 footer-text accentual-heading-h3-font akko-medium"
              id="quality-penny-title"
            >
              Responsabilitate socială
            </h5>
            <span
              id="quality-penny-regress-button"
              className="regress-button"
              style={{ display: "none" }}
            >
              <img
                src={IconDown}
                height="25px"
                alt=""
                className="pull-right footer-mobile-arrow-regress"
              />
            </span>
            <span
              id="quality-penny-collapse-button"
              className="collapse-button"
            >
              <img
                src={IconRight}
                width="25px"
                height="25px"
                alt=""
                className="pull-right footer-mobile-arrow-regress"
              />
            </span>
            <ul
              className="footer-collapsible-list px-0 my-0  collapse footer-collapse"
              id="quality-penny-collapsible"
            >
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a
                  href="https://www.penny.ro/page/sustenabilitate/implicare-sociala"
                  className="footer-link"
                >
                  Implicare socială
                </a>
              </li>
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a
                  href="https://www.penny.ro/page/sustenabilitate/angajati"
                  className="footer-link"
                >
                  Angajați
                </a>
              </li>
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a
                  href="https://www.penny.ro/page/sustenabilitate/energie-clima-mediu"
                  className="footer-link"
                >
                  Energie, climă și mediu
                </a>
              </li>
              <li className="pl-4 pt-2 pb-2 border-grey-top">
                <a
                  href="https://www.penny.ro/page/sustenabilitate/produse-verzi"
                  className="footer-link"
                >
                  Produse verzi
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 border-top-white d-md-none">
            <p className="amatic-bold pl-4 mb-1 pt-3 footer-text-title">
              Urmărește-ne și pe
            </p>
            <h5 className="ml-0 pl-4 mb-1 footer-text accentual-heading-h3-font akko-medium">
              SOCIAL MEDIA &amp; APP
            </h5>
            <ul className="list-unstyled list-inline pl-4">
              <li className="list-inline-item align-middle my-4">
                <a href="https://www.facebook.com/penny.ro">
                  <img className="facebook" src={Facebook} alt="facebook" />
                </a>
              </li>
              <li className="list-inline-item align-middle" />
              <li className="list-inline-item align-middle">
                <a href="https://www.youtube.com/pennymarketromania">
                  <img className="yotube" src={Youtube} alt="youtube" />
                </a>
              </li>
              <li className="list-inline-item align-middle">
                <a href="https://www.instagram.com/pennymarket.ro/">
                  <img className="instagram" src={Instagram} alt="instagram" />
                </a>
              </li>
              <li className="list-inline-item align-middle">
                <a href="https://www.linkedin.com/company/129255/">
                  <img className="linkedin" src={Linkedin} alt="linkedin" />
                </a>
              </li>
              <li className="list-inline-item align-middle d-block">
                <a href="https://play.google.com/store/apps/details?id=int.penny.pennymarket">
                  <img
                    width="100px"
                    height="29.63px"
                    alt=""
                    src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/a482523f-c1d7-4be7-a27e-7b03be1b6d65/Google-Play.png?w=119&fm=webp&lossless=0&q=90"
                    className="google-play"
                  />
                </a>
                <a href="https://itunes.apple.com/ro/app/penny-market/id1091585551?mt=8">
                  <img
                    width="100px"
                    alt=""
                    src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9b3c081c-4d8e-4971-bf73-5b18a475e78f/app-store-badge.svg"
                    className="app-store"
                  />
                </a>
              </li>
            </ul>
          </div>
          <div className="col-sm-12 border-top-white d-none d-md-block d-lg-none">
            <ul className="list-unstyled list-inline pl-4 pt-1 mb-4">
              <li className="list-inline-item">
                <p className="mb-1 pt-3 list-inline-item  amatic-bold">
                  Urmărește-ne și pe
                </p>
              </li>
              <li className="list-inline-item">
                <h5 className="ml-0 pl-4 list-inline-item footer-text accentual-heading-h3-font akko-medium">
                  SOCIAL MEDIA &amp; APP
                </h5>
              </li>
              <li className="list-inline-item ml-5 pl-5 align-middle">
                <a href="https://www.facebook.com/penny.ro">
                  <div className="facebook" />
                </a>
              </li>
              <li className="list-inline-item align-middle" />
              <li className="list-inline-item align-middle">
                <a href="https://www.youtube.com/pennymarketromania">
                  <div className="youtube" />
                </a>
              </li>
              <li className="list-inline-item align-middle">
                <a href="https://www.instagram.com/pennymarket.ro/">
                  <div className="instagram" />
                </a>
              </li>
              <li className="list-inline-item align-middle">
                <a href="https://www.linkedin.com/company/129255/">
                  <div className="linkedin" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://play.google.com/store/apps/details?id=int.penny.pennymarket">
                  <img
                    width="100px"
                    height="29.63px"
                    alt=""
                    src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/a482523f-c1d7-4be7-a27e-7b03be1b6d65/Google-Play.png?w=119&fm=webp&lossless=0&q=90"
                    className="google-play"
                  />
                </a>
                <a href="https://itunes.apple.com/ro/app/penny-market/id1091585551?mt=8">
                  <img
                    width="100px"
                    alt=""
                    src="https://assets-eu-01.kc-usercontent.com:443/b50b8cce-450a-0138-7b43-de6bc2f3ad32/9b3c081c-4d8e-4971-bf73-5b18a475e78f/app-store-badge.svg"
                    className="app-store"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </div>
    <ul className="d-none d-md-block px-0 mt-4 list-unstyled list-inline text-center">
      <li className="list-inline-item mr-4">
        <a
          href="https://www.penny.ro//page/termeni-conditii"
          className="footer-link footer-terms"
        >
          Termeni și condiții
        </a>
      </li>
      <li className="list-inline-item mr-4">
        <a
          href="https://www.penny.ro/pagina/cookies"
          className="footer-link footer-terms"
        >
          Politica de cookies
        </a>
      </li>
      <li className="list-inline-item mr-4">
        <a
          href="https://www.penny.ro//page/confidentialitate"
          className="footer-link footer-terms"
        >
          Politica de confidențialitate
        </a>
      </li>
      <li className="list-inline-item mr-4">
        <a
          href="https://www.penny.ro//page/imprint"
          className="footer-link footer-terms"
        >
          Sediul social
        </a>
      </li>
      <li className="list-inline-item">
        <a
          href="https://www.penny.ro//page/compliance"
          className="footer-link footer-terms"
        >
          Compliance
        </a>
      </li>
    </ul>
    <div className="d-md-none">
      <ul className="list-unstyled footer-terms-mobile">
        <li className="pl-4 pt-2 pb-2 border-grey-bottom footer-terms">
          <a
            className="footer-link"
            href="https://www.penny.ro//page/termeni-conditii"
          >
            Termeni și condiții
          </a>
        </li>
        <li className="pl-4 pt-2 pb-2 border-grey-bottom footer-terms">
          <a className="footer-link" href="https://www.penny.ro/pagina/cookies">
            Politica de cookies
          </a>
        </li>
        <li className="pl-4 pt-2 pb-2 border-grey-bottom footer-terms">
          <a
            className="footer-link"
            href="https://www.penny.ro//page/confidentialitate"
          >
            Politica de confidențialitate
          </a>
        </li>
        <li className="pl-4 pt-2 pb-2 border-grey-bottom footer-terms">
          <a className="footer-link" href="https://www.penny.ro//page/imprint">
            Sediul social
          </a>
        </li>
        <li className="pl-4 pt-2 pb-2 border-grey-bottom footer-terms">
          <a
            className="footer-link"
            href="https://www.penny.ro//page/compliance"
          >
            Compliance
          </a>
        </li>
        <li className="pl-4 pt-2 pb-2 border-grey-bottom footer-terms">
          <button
            id="ot-sdk-btn"
            className="footer-link ot-sdk-show-settings"
            type="button"
          >
            Setări Cookie
          </button>
        </li>
      </ul>
    </div>
  </div>
)

export default Footer
